import { SpinnerLoading } from '@common/core-components';
import { useProfile } from '@common/uaa';
import { UserAccessType } from '@types';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function HomePage() {
  const profile = useProfile();

  const router = useRouter();

  const accessTypeToRoute = {
    '/hubs': [UserAccessType.Admin, UserAccessType.Ct],
    '/riders/management': [UserAccessType.Ro],
    '/deliveries': [UserAccessType.H],
    '/404': [UserAccessType.Unknown],
  };

  useEffect(() => {
    if (profile) {
      for (const [route, accessTypes] of Object.entries(accessTypeToRoute)) {
        if (accessTypes.includes(profile.accessType)) {
          router.push(route);
          break;
        }
      }
    }
  });

  if (!profile) {
    return <SpinnerLoading position="fixed" />;
  }

  return null;
}
